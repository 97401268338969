import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature3 from "components/features/TwoColSingleFeatureWithStats.js";
import Images from "components/blogs/GridWithFeaturedPost.js";
import App from "components/cta/DownloadApp.js"

import Subscribe from "components/forms/Subscribe";


import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";


import Footer from "components/footers/SimpleFiveColumn.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";

const Image = styled.div(props => [
  `background-image: url("https://firebasestorage.googleapis.com/v0/b/rise-afrika-rise.appspot.com/o/new-rara%2Fcow.jpg?alt=media&token=547ea54a-4a0f-49db-ab8d-57ca02ea66b3");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);

// Main Page
export default () => (
  <>
    <Hero />
    <MainFeature />
    <MainFeature2 />
    <MainFeature3 />

    <Features />
    <Images />
    <Subscribe /> 
    <App/>
    <Footer />
  </>
);
