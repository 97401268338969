import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";


const base_app = firebase.initializeApp({
    apiKey: "AIzaSyC7YGjgJ83aNBNOKTMxFPUdzT3tsIWbrkg",
    authDomain: "rise-afrika-rise.firebaseapp.com",
    databaseURL: "https://rise-afrika-rise.firebaseio.com",
    projectId: "rise-afrika-rise",
    storageBucket: "rise-afrika-rise.appspot.com",
    messagingSenderId: "1074861675261",
    appId: "1:1074861675261:web:86a5650e2ec97f2b26e494",
    measurementId: "G-V0Z7FL60FD"
});

export default base_app;