import React, { Component } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import base_app from "../../firebase"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/demo/support.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-56`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-8 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-4 mt-4 lg:mt-0`


export default class Subscribe extends Component {  
  constructor(props){  
    super(props);
    this.state = {
      name:"",
      email:"",
      donate: false, 
      mentor: false, 
      other: false,
      date:new Date()
    }
  
  }

  subscribe = (event) => {

    event.preventDefault();

    
   
  
    base_app.firestore().collection("supporters").add(this.state).then((docRef) =>{  
      fetch('https://rise-afrika-rise.web.app/subscribe',{
        method: "POST",
        body: JSON.stringify(this.state),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
        }).then(
          alert("Thank you for your support!")
        );


    }).catch(function(error) {
      console.error("Error adding document: ", error);
      alert("There was an error when subscribing, please try again");

    });
    


  }


  getSupportType(event){
    switch(event.target.name){
      case "donate":
        this.setState({ donate: event.target.checked });
        break;
      case "mentor":
        this.setState({ mentor: event.target.checked });
        break;
      default:
        this.setState({ other: event.target.checked });
        break;
        
    }


  }

  getName(event){
    this.setState({name: event.target.value})

  }

  getEmail(event){
    this.setState({email: event.target.value})

  }

  render(){
    return(
      <Container style={{backgroundColor:'#f4f4f4'}}>
        <TwoColumn>
          <ImageColumn>
            <br/><br/><br/><br/>
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={true}>
            <TextContent >
              <Heading style={{color:'#ff951c'}}>Support our cause</Heading><br/>
              <Subheading>If you'd like to offer your support, please choose from the options below and we'll contact you:</Subheading>
                <Form>
                  <Description>&nbsp;<Input type="checkbox" name="donate" onChange={this.getSupportType.bind(this)}/> Donate Money&nbsp;&nbsp;</Description>
                  <Description>&nbsp;<Input type="checkbox" name="mentor" onChange={this.getSupportType.bind(this)}/> Mentorship&nbsp;&nbsp;</Description>
                  <Description>&nbsp;<Input type="checkbox" name="other" onChange={this.getSupportType.bind(this)}/> Other&nbsp;&nbsp;</Description>
                </Form>

                  <br/>
                  <br/>

                  <Input type="text" name="name" placeholder="Name" onChange={this.getName.bind(this)}/>
                  <Input type="email" name="email" placeholder="Your Email Address" onChange={this.getEmail.bind(this)} />
                  <br/>
                  <br/>

                  <SubmitButton onClick={this.subscribe.bind(this)} type="button">Support</SubmitButton>

               
              
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
   
    );
  }

  
}
 
