import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import agri from "../../images/demo/drone-two.jpeg";
import cfi from "../../images/demo/cfi.jpg";
import economy from "../../images/demo/farm.jpeg";
import manufacturing from "../../images/demo/agriculture.jpeg";
import training from "../../images/demo/it.jpeg";




const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    img {
      ${tw`w-72 h-48`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);

export default () => {

  const cards = [
    {
      imageSrc: agri,
      title: "Agriculture",
      description: 
      <>
        Greenhouse, vertical farming, drip irrigation, mobile apps, machinery<br/>Aim: To transform agriculture, improve yield, efficiency & profitability
      </>
    },
    {
      imageSrc: manufacturing,
      title: "Manufacturing: Agro-processing & packaging, Recycling" ,
      description: 
      <>
        Wood, paper, textile, leather, metal<br/>Aim: To empower NEET and develop communities

      </>
    },
    { 
      imageSrc: economy,
      title: "Upscale economic activities",
      description: 
      <>
        Business & Skills Training, Support & Mentorship<br/>Aim: To contribute to economic growth

      </>
    },
    {
      imageSrc: training,
      title: "Introduce Technology",
      description: 
      <>
        <br/>Aim: Access, exposure & use of digital technology

      </>
    },
    { 
      imageSrc: cfi,
      title: "Co-operative Financial Institution (CFI)",
      description: 
      <>
        Join self-financing, self-helping, self-sustaining CFI <br/> Aim: Break Poverty Trap, End Financial Exclusion
      </>
      
    }
 
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading> <span tw="text-primary-500"   style={{color:'#ff951c'}}>Activities</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card    style={{color:'#000'}}>
              <span className="imageContainer"> 
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>

      <DecoratorBlob />
    </Container>
  );
};
