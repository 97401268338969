import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as LinkedIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;


const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



export class Footer extends React.Component {  
  render(){
    return (

      <Container>
        <FiveColumns>
          <WideColumn>
          <div>
 
  
          </div>
          </WideColumn>
          <Column>
          <ColumnHeading>Street Address:</ColumnHeading>

            <CompanyDescription>
              17 Manningburg
              <br/>Dimbaza
              <br/>Eastern Cape
              <br/>5671
            </CompanyDescription>
         
          </Column>
          <Column>
            <ColumnHeading>Social Links</ColumnHeading>
            <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/riseafrikarise021">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://linkedIn.com">
                <LinkedIcon />
              </SocialLink>
         
            </SocialLinksContainer>
          </Column>
          <Column>
            <ColumnHeading>Contact Details:</ColumnHeading>
            <LinkList>
              <LinkListItem>
                083 597 8228
              </LinkListItem>
              <LinkListItem>
                060 356 4990            
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
      </Container>
    )

 }
}


export default GoogleApiWrapper({
})(Footer);



